@import "../../../assets/scss/common/variables";
@import "../../../assets/scss/common/mixins";

.bundled-category-footer-menu {

  position: fixed;
  bottom: 0;
  left: 0;
  background: #fff;
  padding: 8px;
  z-index: 100;
  width: 100%;
  box-sizing: border-box;
  box-shadow: -2px 0 8px rgba(0,0,0,.2);
  font-size: 10px;
  display: flex;
  align-items: center;
  gap: 4px;

  &-set {
    line-height: 1;
    span {
      font-size: 20px;
      font-weight: bold;
    }
  }

  &-status {
    flex: 1;
  }

  &-remains {
    padding: 4px 8px;
    border: 1px solid #000;
    line-height: 1;
  }
  &-btn {
    background: $C_RED;
    color: #fff;
    width: 50px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #000;
    line-height: 1;
  }

  @include mq-pc() {
    font-size: 13px;
    width: 800px;
    padding: 8px 16px;
    bottom: 30px;
    left: 50%;
    margin-left: -400px;
  }

}
